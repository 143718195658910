@import "../bootstrap/customizations.scss";
@import "./slick/slick.scss";
@import "./slick/slick-theme.scss";
@import "./intro.scss";
@import "./preview.scss";
@import "./project.scss";
@import "./about-me.scss";

html,
body {
  overflow-x: hidden;
  width: 100vw;
  background-color: #111 !important;
  a {
    color: skyblue;
    &:hover {
      color: skyblue;
    }
    &:visited {
      color: skyblue;
    }
    &:active {
      color: skyblue;
    }
    &:link {
      color: skyblue;
    }
  }
}

.hideScoll {
  @media (max-width: 60em) {
    overflow: hidden;
  }
}
