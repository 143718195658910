.project-title {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  padding: 10px 4px 10px 25px;
  font-weight: 600;

  background-color: #673ab7;
  z-index: 200;
  width: 100%;
  @media (max-width: 60em) {
    margin: 0px 0px 0px 0px;
    position: fixed;
  }
}

.project-title-mobile {
  @media (min-width: 60em) {
    display: none;
  }
}
.project-title-desktop {
  @media (max-width: 60em) {
    display: none;
  }
}

.project-content {
  .details-col {
    padding: 25px;

    @media (min-width: 60em) {
      margin: 10px 0px 0px 0px;
    }

    @media (max-width: 60em) {
      margin-top: 40px;
    }

    h3 {
      text-transform: uppercase;
      background: rgba(255, 255, 255, 0.5);
      display: inline-block;
      padding: 2px 4px 2px 4px;
      color: #673ab7;
    }

    a {
      color: skyblue;
    }

    p {
      margin-bottom: 0px;
      font-size: 1.35em;
    }

    span {
      font-size: 1.35em;
    }

    .link {
      font-size: 1.35em;
    }

    .dead-link {
      text-decoration: line-through;
    }

    .tag {
      padding: 2px 4px 2px 4px;
      margin: 2px 2px 2px 2px;
      display: inline-block;
      background-color: rgba(50, 0, 100, 0.2);
      font-size: 1.2em;
    }
  }

  .media-col {
    min-height: 200px;
    max-height: 400px;
    padding: 2em;

    @media (min-width: 60em) {
      margin: 0px 0px 55px 0px;
    }
    padding-top: 0px;
  }

  .carousel {
    max-width: 800px;
    max-height: 400px;
    margin: 55px 0px 70px 0px;

    .slick-slide {
      img {
        width: 100%;
        margin: auto;
        height: auto;
      }

      iframe {
        width: 100%;
        min-height: 400px;
      }
    }
  }
}

// Modal
.modal-overlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;

  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;

  .modal {
    background-color: #673ab7;
    color: white;
    margin: 0 auto;
    padding: 0;
    display: block;
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: 60em) {
      height: auto;
      margin: 3% auto;
      max-height: 90%;
      min-height: 85%;
      min-width: 500px;
      width: 75%;
      padding: 3em;
    }

    > iframe,
    > div {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .close-modal {
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
      background-color: lighten(rgba(255, 255, 255, 0.7), 20%);
    }
    color: #673ab7;
    padding: 2px 12px 8px;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    z-index: 1;

    position: absolute;
  }
}
