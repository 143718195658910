.preview-description {
  font-size: 0.75em;
  font-style: italic;
}

.square {
  border: 3px #111 solid;
  float: left;
  position: relative;
  width: 30%;
  padding-bottom: 30%; /* = width for a 1:1 aspect ratio */
  background-color: #1e1e1e;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    padding-bottom: 80%;
  }
}

/*  For responsive images */

.preview-content .rs {
  width: auto;
  height: auto;
  max-height: 90%;
  max-width: 100%;
}

/*  For responsive images as background */

.bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; /* you change this to "contain" if you don't want the images to be cropped */
  color: #fff;
}

.preview-content {
  position: absolute;
  height: 90%; /* = 100% - 2*5% padding */
  width: 90%; /* = 100% - 2*5% padding */
  padding: 5%;
  font-size: 26px;
  font-weight: 400;
}

#passtimeV2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url("../images/passtimev2-1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../images/passtimev2-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../images/passtimev2-gif.gif");
    background-position: -9999px -9999px;
  }

  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../images/passtimev2-gif.gif");
  }
}


#lab100 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/lab-1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lab100-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lab100-gif.gif");
    background-position: -9999px -9999px;
  }

  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lab100-gif.gif");
  }
}

#colorfactory {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/color-1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/colorfactory-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/colorfactory-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/colorfactory-gif.gif");
  }
}

#twitch {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/twitch2.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/twitch-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/twitch-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/twitch-gif.gif");
  }
}

#bedroom {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/bedroom-jammer2.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/bedroom-jammer-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/bedroom-jammer-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/bedroom-jammer-gif.gif");
  }
}

#glitch {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/glitch-the-world1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glitch-the-world-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glitch-the-world-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glitch-the-world-gif.gif");
  }
}

#lovelocks {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/lovelocks9.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lovelocks-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lovelocks-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/lovelocks-gif.gif");
  }
}

#svrround {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/svrround1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/svrround-gif3.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/svrround-gif3.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/svrround-gif3.gif");
  }
}

#canale {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/canale-quattro1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/canale-quattro-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/canale-quattro-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/canale-quattro-gif.gif");
  }
}

#magic {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/magic-is-real1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/magic-is-real1.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/magic-is-real1.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/magic-is-real1.gif");
  }
}

#haptec {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/haptec1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/haptec-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/haptec-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/haptec-gif.gif");
  }
}

#passtime {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/passtime1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/passtime-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/passtime-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/passtime-gif.gif");
  }
}

#immigrater {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/immigrater1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/immigrater-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/immigrater-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/immigrater-gif.gif");
  }
}

#glove {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/glove-phone1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glove-phone-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glove-phone-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/glove-phone-gif.gif");
  }
}

#dimensions {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/dimensions1.jpg");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/dimensions-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/dimensions-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/dimensions-gif.gif");
  }
}

#emoji {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/emoji1.png");
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/emoji-gif.gif");
  }
  .preload-gif {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/emoji-gif.gif");
    background-position: -9999px -9999px;
  }
  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/emoji-gif.gif");
  }
}
