#about-me-btn {
  background: #8ab73a;
  text-align: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: white;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  z-index: 4;
  position: fixed;
  bottom: 1em;
  right: 1em;

  span {
    top: -1em;
  }

  &:hover {
    cursor: pointer;
    background: lighten(#8ab73a, 10%);
  }

  width: 70px;
  height: 70px;

  @media (max-width: 60em) {
    width: 60px;
    height: 60px;
  }
}

.about-content {
  p {
    font-size: 20px;
    @media (min-width: 60em) {
      padding: 5em;
    }
    @media (max-width: 60em) {
      margin-top: 70px;
    }
  }

  h1 {
    margin: 0;
    color: #673ab7;
    color: rgba(255, 255, 255, 1);
    padding: 6px 4px 10px 14px;
    @media (max-width: 60em) {
      margin: 0px 0px 0px 0px;
    }
  }
}
