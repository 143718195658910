@import "./lightning.scss";

#welcome-bg {
  display: none;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  position: absolute;

  #welcome {
    font-size: 8vw;
    color: rgba(100, 100, 100, 0.4);
    top: -1.2vw;
    left: 2vw;
    padding: 0;
    margin: 0;
    position: absolute;
    align-self: flex-start;

    span {
      padding: 0;
      margin: -5px;
    }

    @media (max-width: 1100px) {
      top: 28vw;
    }
  }

  #scroll-btn {
    font-size: 2.75em;
    color: white;
    padding: 0;
    position: absolute;
    bottom: 1.3em;
    div {
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
    }
    img {
      width: 35px;
    }
  }
}

.pulse1 {
  animation: pulse1 1000ms linear normal infinite;
}

@keyframes pulse1 {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.pulse2 {
  animation: pulse2 1000ms linear normal infinite;
}

@keyframes pulse2 {
  from {
    opacity: 0.5;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
  }
}

.pulse3 {
  animation: pulse3 1000ms linear normal infinite;
}

@keyframes pulse3 {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
